import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3b22154"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-message__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "add-message",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    "after-close": _ctx.afterClose
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        key: "submit",
        type: "primary",
        class: "add-message__submit",
        loading: _ctx.confirmLoading,
        disabled: !_ctx.model.message,
        onClick: _ctx.addMessage
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("ADD_MESSAGE.SEND")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading", "disabled", "onClick"]),
      _createVNode(_component_a_button, {
        key: "back",
        onClick: _ctx.handleCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("ADD_MESSAGE.CANCEL")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.t("ADD_MESSAGE.HEADER")), 1 /* TEXT */),
      _createVNode(_component_a_form, {
        ref: "form",
        autocomplete: "off",
        model: _ctx.model,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { name: "message" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_textarea, {
                class: "add-message__textarea",
                placeholder: _ctx.t('ADD_MESSAGE.PLACEHOLDER'),
                autocomplete: "off",
                value: _ctx.model.message,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.message) = $event))
              }, null, 8 /* PROPS */, ["placeholder", "value"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "after-close"]))
}