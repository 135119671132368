
import { defineComponent, ref, Ref, inject, watch, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";
import { AxiosStatic } from "axios";
import { RuleObject } from "ant-design-vue/es/form/interface";

interface Model {
  message: string;
}

export const AddMessageComponent = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    visitId: {
      type: String,
      required: true,
    },
  },
  emits: ["after-confirm-success", "update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;

    const confirmLoading: Ref<boolean> = ref(false);
    const model: Model = reactive({
      message: "",
    });

    const rules: Record<string, Array<RuleObject>> = reactive({
      message: [
        {
          type: "string",
          required: true,
          message: t("ADD_MESSAGE.FIELD_REQUIRED"),
        },
      ],
    });
    const visible: Ref<boolean> = ref(false);

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
      }
    );

    const addMessage = () => {
      confirmLoading.value = true;

      return http
        .post(`patient-portal/api/conversation/message/${props.visitId}`, {
          message: model.message,
        })
        .then(() => {
          visible.value = false;
          confirmLoading.value = false;
          notification.open({
            message: t("ADD_MESSAGE.SUCCESS"),
            class: "success",
          });
          emit("after-confirm-success");
        })
        .catch(() => {
          notification.open({
            message: t("ERROR.4957"),
            class: "error",
          });
        });
    };
    const handleCancel = () => {
      visible.value = false;
    };
    const afterClose = () => {
      model.message = "";
      emit("update:modelValue", false);
    };

    return {
      confirmLoading,
      addMessage,
      afterClose,
      model,
      rules,
      handleCancel,
      visible,
      t,
    };
  },
});

export default AddMessageComponent;
